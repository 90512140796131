<template>
  <div class="device">
    <van-cell-group>
      <van-cell title="设备名称" value="内容" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="产品名称" value="内容" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="设备所属组织" value="内容" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="设备维修人员" value="内容" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="提升机" value="内容" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="提升机2" value="内容" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="设备管理人员" value="" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="郭庄" value="内容" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="晓庄" value="内容" />
    </van-cell-group>
  </div>

</template>

<script>
export default {
  name: "device"
}
</script>

<style scoped>

</style>
